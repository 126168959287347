import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row } from "react-grid-system"
import InnerContentContainer from "../layout/innerContentContainer"
import Column from "../layout/column"
import Separator from "../layout/separator"
import PlanButtons from "./planButtons"

const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "sinc-time-and-attendance-software.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strapiPricing {
      headline
      termsText
      planReductionText
    }
  }
`

const Pricing = ({ signupLink }) => {
  const {
    backgroundImage,
    strapiPricing: { headline, termsText, planReductionText },
  } = useStaticQuery(query)
  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundImage.childImageSharp.fluid}
      backgroundColor={"white"}
      className="hero-background full-height"
      fadeIn={false}
      loading={"eager"}
    >
      <div className="gradient-background">
        <InnerContentContainer>
          <>
            <Container>
              <Row>
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "2rem",
                    textAlign: "center",
                  }}
                >
                  <a id="pricing" />
                  <h2 className="white-text">{headline}</h2>
                </Column>
              </Row>

              <PlanButtons signupLink={signupLink} />
              <Row style={{ marginTop: "1rem" }}>
                <Separator light />
                <Column
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <p className="white-text">{termsText}</p>
                  <span className="white-text">{planReductionText}</span>
                </Column>
              </Row>
            </Container>
          </>
        </InnerContentContainer>
      </div>
    </BackgroundImage>
  )
}

export default Pricing
