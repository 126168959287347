export const FREE_PLAN = "free"
export const STARTER_PLAN = "starter"
export const GROWTH_PLAN = "growth"
export const ENTERPRISE_PLAN = "enterprise"

// users
export const USERS_LABEL = "USERS"

// free
const TIME_SHEETS_LABEL = "Time Sheets"
const LOCATIONS_LABEL = "Locations"
const MESSENGER_LABEL = "Messenger"
// starter
const JOB_TRACKING_LABEL = "Job Tracking"
const TASK_TRACKING_LABEL = "Task Tracking"
const COST_CODE_TRACKING_LABEL = "Cost Codes"
const SCHEDULING_LABEL = "Scheduling"
const ADDITIONAL_ADMINS_LABEL = "Additional Admin Users"
const OVERTIME_LABEL = "Overtime Calculations"
const SHIFT_TEMPLATES_LABEL = "Shift Templates"
// growth + ent
const STAFF_LIVE_LOCATIONS_LABEL = "Staff Live Locations"
const DAILIES_LABEL = "Daily Job Reports"
const INJURY_SIGN_OFF_LABEL = "Injury Sign Off"
const GEOFENCE_CLOCK_IN_LABEL = "Job Start via Geofence"
const TIMEOFF_LABEL = "Time Off"

const Features = [
  {
    label: TIME_SHEETS_LABEL,
    [FREE_PLAN]: true,
    [STARTER_PLAN]: true,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: LOCATIONS_LABEL,
    [FREE_PLAN]: true,
    [STARTER_PLAN]: true,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: USERS_LABEL,
    [FREE_PLAN]: true,
    [STARTER_PLAN]: true,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: MESSENGER_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: SCHEDULING_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: JOB_TRACKING_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: OVERTIME_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: COST_CODE_TRACKING_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: TASK_TRACKING_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: ADDITIONAL_ADMINS_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: SHIFT_TEMPLATES_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: TIMEOFF_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: true,
    [ENTERPRISE_PLAN]: true,
  },
  // {
  //   label: TEAMS_LABEL,
  //   [FREE_PLAN]: false,
  //   [STARTER_PLAN]: false,
  //   [GROWTH_PLAN]: true,
  //   [ENTERPRISE_PLAN]: true,
  // },
  {
    label: DAILIES_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: false,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: INJURY_SIGN_OFF_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: false,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: STAFF_LIVE_LOCATIONS_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: false,
    [ENTERPRISE_PLAN]: true,
  },
  {
    label: GEOFENCE_CLOCK_IN_LABEL,
    [FREE_PLAN]: false,
    [STARTER_PLAN]: false,
    [GROWTH_PLAN]: false,
    [ENTERPRISE_PLAN]: true,
  },
]

export default Features
