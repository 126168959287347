import React from "react"
import Segmented from "rc-segmented"
import { Row } from "react-grid-system"
import styled from "styled-components"
// eslint-disable-next-line import/no-unassigned-import
import "rc-segmented/assets/index.css"

const LabelText = styled.span`
  color: ${({ selected }) => (selected ? "#F9F9F9" : "#ACB8C3")};
  font-weight: 500;
  @media (max-width: 360px) {
    font-size: 15px;
  }
`

const LabelOption = styled(Row)`
  padding: 10px 25px;
  text-align: center;
  @media (max-width: 360px) {
    padding: 7px 20px;
  }
`
const Switch = styled(Segmented)`
  &.rc-segmented {
    border-radius: 30px;
    padding: 2px 3px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
  }
  .rc-segmented-group {
    border-radius: 30px;
  }
  .rc-segmented-group {
    border-radius: 30px;
  }
  .rc-segmented-thumb {
    border-radius: 30px;
  }
  .rc-segmented-item {
    border-radius: 30px;
  }
  .rc-segmented-item-selected {
    background-color: #1e96fa;

    border-radius: 30px;
  }
  .rc-segmented-thumb {
    background-color: #1e96fa;
  }
`
const BadgeWrapper = styled(Row)`
  border-radius: 20px;
  padding: 4px 20px;
  position: absolute;
  top: -15px;
  left: -34px;
  box-shadow: 0px 3px 6px #00000024;
  background-color: #26a65b;
  @media (max-width: 425px) {
    left: -20px;
  }
  @media (max-width: 360px) {
    padding: 4px 15px;
    left: -10px;
  }
`

const BadgeText = styled.span`
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 360px) {
    font-size: 11px;
  }
`

const PricingSwitch = props => {
  const { showAnnualPricing = false, setShowAnnualPricing } = props

  return (
    <Row
      style={{
        marginTop: "15px",
        marginBottom: "20px",
      }}
    >
      <Row
        style={{
          alignItems: "flex-start",
          position: "relative",
          overflow: "visible",
        }}
      >
        <Row>
          <Switch
            value={showAnnualPricing}
            onChange={() => setShowAnnualPricing(!showAnnualPricing)}
            options={[
              {
                label: (
                  <LabelOption>
                    <LabelText selected={showAnnualPricing}>
                      {"Billed Yearly"}
                    </LabelText>
                  </LabelOption>
                ),
                value: true,
              },
              {
                label: (
                  <LabelOption>
                    <LabelText selected={!showAnnualPricing}>
                      {"Billed Monthly"}
                    </LabelText>
                  </LabelOption>
                ),
                value: false,
              },
            ]}
          />
        </Row>
        <BadgeWrapper>
          <BadgeText textColor="light" bold>
            {"Save Up To 10%"}
          </BadgeText>
        </BadgeWrapper>
      </Row>
    </Row>
  )
}

export default PricingSwitch
