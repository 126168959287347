import React from "react"
import styled from "styled-components"

// New component intended to be used universally, use where possible

const LargerThanMediumWrapper = styled.div`
  display: none;
  @media (min-width: 1140px) {
    display: flex !important;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

export default LargerThanMediumWrapper
