import React from "react"
import { FaStar } from "react-icons/fa"
import styled from "styled-components"
import { Row } from "react-grid-system"

const Wrapper = styled(Row)`
  position: absolute;
  right: 3px;
  top: -22px;
  overflow: visible;
  border-radius: 4px;
  border-bottom-right-radius: 0px;
  background-color: var(--clr-ripe-mango);
  padding: 5px 45px;
`
const Text = styled.span`
  font-size: 14px;
  color: var(--clr-white);
  font-weight: 500;
  margin-left: 5px;
`
const Triangle = styled.div`
  border-right: 7px solid transparent;
  border-top: 25px solid var(--clr-ripe-mango);
  position: absolute;
  right: 0px;
  top: 31px;
`

const MostPopularBadge = props => {
  return (
    <Wrapper>
      <Row align="center" justify="center">
        <FaStar size={18} color={"#ffffff"} />
        <Text>Most Popular</Text>
      </Row>
      <Triangle />
    </Wrapper>
  )
}

export default MostPopularBadge
