import React from "react"
import { VscLoading } from "react-icons/vsc"

const LoadingPlaceHolder = () => (
  <div
    style={{
      width: "100%",
      miHeight: 80,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <VscLoading />
  </div>
)
export default LoadingPlaceHolder
