import React from "react";
import styled from "styled-components";


// New component intended to be used universally, use where possible

const LargeAndSmallerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1140px) {
    display: none !important;
  }
`;

export default LargeAndSmallerWrapper;
